

























import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { CreateCommunityViewModel } from '../viewmodels/create-community-viewmodel'

@Observer
@Component({
  components: {
    'basic-information': () => import('@/modules/community-organize/components/forms/basic-information.vue'),
    'more-information': () => import('@/modules/community-organize/components/forms/more-information.vue'),
    'main-menu': () => import('@/modules/community-organize/components/forms/main-menu.vue'),
    contributor: () => import('@/modules/community-organize/components/forms/contributor.vue'),
    rule: () => import('@/modules/community-organize/components/forms/rule.vue'),
    faq: () => import('@/modules/community-organize/components/forms/faq.vue'),
    'radio-important': () => import('@/modules/community-organize/components/forms/radio-important.vue'),
    classification: () => import('@/modules/community-organize/components/forms/classification.vue'),
  },
})
export default class CreateCommunityPage extends Vue {
  @Provide() vm = new CreateCommunityViewModel()
}
